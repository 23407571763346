<template>
    <div class="container-fluid" :class="bgcolor" id="education">
        <div class="container section">
            <h4 class="headerText">{{ sectionName }}</h4>
            <div class="sidebar">
                <h2>{{ dataTitle }}</h2>
                <div class="columns" v-html="dataIntro"></div>
            </div>
            <div class="postscontainer">
                <div class="row justify-content-center">
                    
                    <!-- !! do contentCards with slots or own component! -->
                    <!-- <div v-for="post in postData" :key="post.id">
                        <contentCard url></contentCard>
                    </div> -->


                    <div class="col-lg-3 postitem" v-on:click="modalStuff(post)" data-toggle="modal" data-target="#myModalHome" v-for="post in postData" :key="post.id">
                        <!-- <img class="img-fluid" v-bind:src="post.image" alt="image" border="0" />	 -->
                        <div v-if="post.featured_media !== 0">
                            <img class="img-fluid " v-bind:src="post._embedded['wp:featuredmedia'][0].source_url" alt="image" border="0" />
                        </div>
                        <div class="heading2" >{{ post.title.rendered }}</div>
                        <div v-html="post.content.rendered.slice(0, 200).concat('... (zum weiterlesen bitte klicken)')"></div>
                    </div>
                    <nuxt-link :to="targetPage" class="col-lg-2 align-self-center">
                        <div class="links"><div class="button--green accentcolor">Mehr</div></div>
                        <!-- button--grey -->
                        <!-- <div class="links"><a href="https://github.com/nuxt/nuxt.js" target="_blank" class="button--grey"Mehr</a></div> -->
                    </nuxt-link>
                </div>
            </div>

        </div>

        <!-- Use The BOOTSTRAP Modal -->
        <div class="modal fade" id="myModalHome">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                        <h4 class="modal-title">{{ modalTitle }}</h4>
                        <!-- <h4 class="modal-title">dsafkdakf</h4> -->
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                    <!-- Modal body -->
                    <div class="modal-body" v-html="modalContent"></div>
                    <div v-if="modalFeaturedImage">
                        <img class="img-fluid" v-bind:src="modalFeaturedImage" alt="image" border="0" />
                    </div>
                    <!-- Modal footer -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios';

export default {
    components: {
        
        },
    props: ['bgcolor', 'category', 'targetPage', 'sectionName'],
    data() {
        return {
            postData: [],
            dataTitle: [],
            dataIntro: [],
            showModal: false,
            modalTitle: '',
            modalContent: '',
            modalFeaturedImage: ''
        };
    },
    mounted() {

        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?orderby=date`)
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true`)
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?categories=${this.category}`)
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?per_page=2&categories=${this.category}&orderby=date`)
        // axios.get(`${this.wpData.rest_url}/wp/v2/posts?orderby=date`)

        // #####################################################################################
        // what was before START
        // #####################################################################################
        // this is a workaround.. wp api cannot use AND statement (becomes OR), so it has to be done with javascript
        // I could do it all in here.. but then if there is a cat 9, then on page will be less then 3 showing
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
        axios.get(`https://esero.de/wp-interface/index.php/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
            .then(response => { 
                response.data.map( (item) => {
                    if (item.categories.includes(22)) {
                        this.dataTitle = item.title.rendered
                        this.dataIntro = item.content.rendered
                    } 
                    // else {
                        //     this.postData.push(item)
                    // }
                })
            })
            .catch(e => { this.errors.push(e) });

        // the following excludes category 9, which is the introduction text
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}&categories_exclude=22`)
        axios.get(`https://esero.de/wp-interface/index.php/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}&categories_exclude=22`)
            .then(response => { this.postData = response.data })
            .catch(e => { this.errors.push(e) })


        // #####################################################################################
        // what was before END
        // #####################################################################################



        // #####################################################################################
        // notes BEGINNING
        // #####################################################################################

        // loop through all posts of that category until find wpterm slug matching sectionintro (and this needs to be named same way everywhere)
            // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
            //     .then(response => {
            //         response.data.map( (item) => {
            //             item._embedded['wp:term'].map( (item2) => {
            //                 if (item2.slug.includes("sectionintro")) {
            //                     console.log('durch')
            //                     this.dataTitle = ['bla']
            //                     this.dataIntro = ['bal']
            //                 }
            //             })
            //         })
            //     })
            //     .catch(e => { this.errors.push(e) });

// var test
// response.data.map ( (item) => {
// 	// test = item._embedded['wp:term'][0]
// 	item._embedded['wp:term'][0].map ( (item2) => {
// 		if (item2 == 'sectiontextaktionen') {
//         	test = item
// 		}
// 	})
// })

// response.data.map ( (item) => {
// 	// item.filter(bla => bla._embedded['wp:term'][0] === 'sectionintroaktionen')
// 	console.log(item._embedded['wp:term'][0].filter(bla => bla.slug === 'sectionintroaktionen'))
// 	// console.log(item)
// })

// ##### for complex 
// rename in every section to have common name sectionintrotext
// var blub = response.data.filter(post => post._embedded['wp:term'][0].find((item) => item.slug === 'sectionintrounterricht'))
// return array

// same functionality with filter
// var blub = response.data.filter(post => post._embedded['wp:term'][0].filter((item) => item.slug === 'sectionintrounterricht')).shift()
// returns object

// this did not work yet but should be working
// var blub
// response.data.map( (item) => {
//     if (item._embedded['wp:term'][0].find((item) => item.slug === 'sectionintrounterricht')) {
//         blub = item.title.rendered
//     }	
// })

        // #####################################################################################
        // notes END
        // #####################################################################################

    },
    methods: {
        modalStuff(post) {
            // this.showModal = true // >>> uncomment only when own vue modal component is used
            console.log('fadfd')
            this.modalTitle = post.title.rendered
            this.modalContent = post.content.rendered
            if (post.featured_media !== 0) {
                this.modalFeaturedImage = post._embedded['wp:featuredmedia'][0].source_url
            } else { this.modalFeaturedImage = '' }
        }
    }
    // ,
    // computed: {
    //     computedPostData() {
    //        return this.postData
    //     }
    // }
};


</script>

<style scoped>
/* many styles are already defined in index.. which is not scoped */

.accentcolor {
    color: #009ada;
    /* border: 1px solid #009ada; */
    /* color: white; */
    /* border: 1px solid white; */
    border: 1px solid #009ada;
}
.button--green:hover {
    color: white;
    background-color: #009ada;
}


.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}

/* 3 posts preview */
.postscontainer {
    margin-top: 180px;
}
.postitem {
    padding: 20px;
    margin: 10px;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    background-color: white;
    color: #212529;
    /* -webkit-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.4); */
    /* -moz-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.4); */
    /* box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.4); */
    /* box-shadow: 0px 0px 6px 1px rgba(241, 8, 8, 0.7); */
    /* z-index: 99999999; */
}
.postitem:hover {
    /* -webkit-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.75); */
    /* -moz-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.75); */
    box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.75);
    /* box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.7); */
    cursor: pointer;
}
.heading2 {
    /* font-family: "Open Sans", sans-serif; */
    font-family: "Open Sans", sans-serif;
    /* font-family: "Open Sans Condensed", sans-serif; */
    text-transform: none;
    font-size: 25px;
    line-height: 35px;
    font-weight: 300;
    letter-spacing: -0.5px;
}
</style>

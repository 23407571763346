<template>
<!-- to do -->
<!-- das logo soll in die navbar rein
als initial header kommt dann eher der text Vom Weltall ins Klassenzimmer auf das Bild
siehe Spanien Seite.. sonst siehe Seite Austria
Die Reiter sollen heißen
News -  Live - LehrerInnenfortbildung - Unterrichtmaterialien - Aktionen und Wettbewerbe - Über uns - Kontakt
alles groß schreiben bitte
wenn zu lang dann LuL-Fortbildungen - Unterricht
oder
Kontakt zu Über uns packen
https://www.geographie.uni-bonn.de/forschung/ags/ag-fernerkundung/eo-live -->

<!-- issues -->
<!-- weird.. when I load eg unterricht then css classes from index (althought not scoped) are not applied.. when first opening homepage and going to unterricht works -->
<!-- if it contnues just put the general styles in the style.css -->
<!-- clean up styles.. many redundancies, see what can be put generally in index not scoped, and which ones should be scoped in the individual components -->
<!-- uninstall vue js modal and remove from package json -->
<!-- fix initial image in navbar.. starts out with a static width currently -->

<!-- notes -->
<!-- in any case my ideas were a lot better.. a logo in the center of header image and a transparent navbar background which turn solid after scroll -->

  <div>


    <!-- <div v-cloak> -->
      <div id="intro" class="header-container fs">

          <div class="bcg"></div> <!-- background image -->
          <header class="wrapper" id="headerBox">
              <!-- <h1 class="title">Build Something Amazing!</h1> -->
              <!-- <p>Learn ScrollMagic and GreenSock to create your own site from scratch.</p> -->

                  <!-- <img class="mainLogo"> -->

                  <div class="flexContainerCenteredColumn">
                    <!-- ---------------- -->
                    <!-- <div class="flexItemColumn mainLogo" style="position: fixed; left: 0; right: 0; top: 85px;">

                      <div class="captionadjust" style="padding: 0px; ">Event News</div>
                      <h3>Live-Call mit der ISS an der Ruhr-Universität Bochum</h3>
                      <div class="row">
                        <div class="col-sm-12 col-md-8">
                          <p>Am 15. Oktober 2019  ab ca. 12:00 Uhr findet im Rahmen von „Beyond“ – ESA Education – ein Live-Call mit Astronaut Luca Parmitano auf der ISS an der Ruhr-Universität Bochum (Hörsaal HGB 10) statt. 
                            Wer diesem Gespräch mit vier Lehrkräften aus Nordrhein-Westfalen folgen möchte, ist herzlich eingeladen.
                            <br><br> Hier finden Sie das das vorläufige <a href="https://esero.de/wp-interface/wp-content/uploads/IFC_Agenda_RUB.pdf" target="_blank" style="color:white"><strong>Programm</strong></a>.
                            <br><a href="https://airtable.com/shrRQW9OZo6rzIHM4" target="_blank" style="color:white"><strong>ANMELDUNG</strong></a>
                          </p>
                        </div>
                        <div class="col-sm-12 col-md-4">
                          <img src="~/assets/Luca_Parmitano.jpg" class="rounded img-fluid">
                        </div>
                      </div>
                    </div> -->
                    <!-- ---------------- -->
                    <div class="flexItemColumn mainLogo">
                      <!-- <img class="flexItemColumn" src="~/assets/esero_flag_sign_Germany_blue.png">
                      <div class="flexItemColumn captionadjust">Vom Weltall ins Klassenzimmer</div> -->
                      <!-- <div class="flexItemColumn captionadjust" v-html="headerTitle"></div> -->
                      <!-- temporary variant corona: -->
                      <img class="flexItemColumn" src="~/assets/esero_webbanner_v3.png">
                    </div>
                  </div>

                  <!-- <img class="mainLogo" src="~/assets/esero_flag_sign_Germany_blue.png"> -->
                  <!-- <img class="mainLogo" src="~/assets/esero_country_name_blue_r.png"> -->


                  <!-- <div v-if="windowwidth < 600">
                      <img class="mainLogo" src="~/assets/esero_country_name_blue_r.png" alt="Responsive image">
                  </div>
                      <div v-else>
                          <img class="mainLogo" src="~/assets/esero_flag_sign_Germany_blue.png" style="width: 100%" alt="Responsive image">
                      </div> -->
                  <!-- <figcaption class="captionadjust" v-html="headerTitle"></figcaption> -->
                  <!-- <div class="captionadjust" v-html="headerTitle"></div> -->
          </header>

          <!-- <span class="scroll-hint">Scroll Down</span> -->
          <div id="arrowArea">
            <!-- <div class="arrowBackground"></div> -->
            <div id="downArrow3"><span></span><span></span><span></span></div>
          </div>
      </div>
    <!-- </div> -->

<div class="main-container">

      <!-- THIS ALL SHOULD BE POSSIBLE WITH ONLY WITH SCROLLMAGIC AND GSOC (dont get why css used here, like the no repeat feature would be great) -->

      <!-- FIRST SECTION STATIC -->
      <!-- <div class="container-fluid whitegrey"> -->
      <div class="whitegrey">
        <div class="container">
            <!-- <h4 class="headerText animateUp">Prologue</h4> -->
            <div id="education" class="flexContainerCenteredColumn section">
                <i class="fas fa-rocket animateUp" style="font-size: 80px; margin: auto; color: #009ada"></i>
                <h2 class="flexItemColumn animateUp animateUp2" style="text-align: center">{{ dataTitle }}</h2>
                <div class="flexItemColumn animateUp animateUp3" style="max-width: 600px; text-align: center" v-html="dataIntro"></div>
                <div class="d-flex flex-row justify-content-center align-items-center">
                  <nuxt-link style="text-decoration: none" to="/news" class="erfahreMehr2 d-flex justify-content-center"><div class="button--green animateUp animateUp3" style="margin-right: 10px">Zu den NEWS</div></nuxt-link>
                  <!-- <div class="links"><div class="button--green accentcolor">Newsletter abonnieren</div></div> -->
                  <div class="erfahreMehr2 d-flex justify-content-center"><a class="button--green animateUp animateUp3" href="https://airtable.com/shrU7Nqxm3K7lK9GX" target="_blank" style="text-decoration: none; margin-left: 10px">Newsletter abonnieren</a></div>
                </div>
            </div>
            <!-- <div id="mainTasks" class="flexContainerRow">
              <div class="flexItemRow animateUp">Fortbildungen</div>
              <div class="flexItemRow animateUp">Unterrichtsmaterialien</div>
              <div class="flexItemRow animateUp">Aktionen & Wettbewerbe</div>
            </div> -->

            <!-- <div id="mainTasks" class="section d-flex flex-row justify-content-center">
              <div class="mainTasksContainer d-flex flex-column justify-content-center align-items-center">
                <i class="fas fa-chalkboard-teacher mainTastItem animateUp" style="font-size: 60px; color: #009ada"></i>
                <h3 class="mainTastItem animateUp">Fortbildungen</h3>
                <p class="mainTastItem animateUp">Wir bieten Fortbildungen an.</p>
              </div>
              <div class="mainTasksContainer d-flex flex-column justify-content-center align-items-center">
                <i class="fas fa-user-graduate mainTastItem animateUp" style="font-size: 60px; color: #009ada"></i>
                <h3 class="mainTastItem animateUp">Unterrichtsmaterialien</h3>
                <p class="mainTastItem animateUp">Didaktisch und interaktiv. Unsere Unterrichtsmaterialien</p>
              </div>
              <div class="mainTasksContainer d-flex flex-column justify-content-center align-items-center">
                <i class="fas fa-user-astronaut mainTastItem animateUp" style="font-size: 60px; color: #009ada"></i>
                <h3 class="mainTastItem animateUp">Aktionen & Wettbewerbe</h3>
                <p class="mainTastItem animateUp">Spannende Themenbereiche hautnah und live erleben.</p>
              </div>
            </div> -->

            <div id="mainTasks" class="row section" style="text-align: center">
              <div class="mainTasksContainer col-lg-4">
                <i class="fas fa-chalkboard-teacher mainTastItem animateUp" style="font-size: 60px; color: #009ada"></i>
                <h3 class="mainTastItem animateUp">Fortbildungen</h3>
                <p class="mainTastItem animateUp">Wir bieten Fortbildungen für Lehrer*innen an, damit Bereiche der Raumfahrt reibungslos und erfolgreich in den modernen Lehrplan der MINT Fächer integriert werden können.</p>
              </div>
              <div class="mainTasksContainer col-lg-4">
                <i class="fas fa-user-graduate mainTastItem animateUp" style="font-size: 60px; color: #009ada"></i>
                <h3 class="mainTastItem animateUp">Unterrichtsmaterialien</h3>
                <p class="mainTastItem animateUp">Wir entwickeln innovative und interaktive Lerneinheiten für die Anwendung im Schulunterricht und bieten diese zum freien Download an.</p>
              </div>
              <div class="mainTasksContainer col-lg-4">
                <i class="fas fa-user-astronaut mainTastItem animateUp" style="font-size: 60px; color: #009ada"></i>
                <h3 class="mainTastItem animateUp">Aktionen & Wettbewerbe</h3>
                <p class="mainTastItem animateUp">Wir organisieren regelmäßig Aktionen und Wettbewerbe, in denen Schüler*innen spannende Themenbereiche praktisch miterleben und -gestalten können.</p>
              </div>

            </div>

        </div>
      </div>

      <div class="container-fluid noPaddingXWidth whitegrey section">
        <div class="row triggerTweenSides">
          <SectionHalf
              class="col-lg-6 containerHalfSection tweenFromLeft"
              bgcolor="whitegrey"
              category="15"
              targetPage="fortbildungen"
              sectionName="Fortbildungen"
          />
          <div class="col-lg-6 d-flex align-items-center"><img id="imgFortbildungen" class="img-fluid tweenFromRight" src="~/assets/fortbildungen_gruppe.jpg" alt=""></div>
        </div>
        <div class="row triggerTweenSides">
          <SectionHalf
              class="col-lg-6 containerHalfSection tweenFromLeft"
              bgcolor="whitegrey"
              category="2"
              targetPage="unterricht"
              sectionName="Unterrichtsmaterialien"
          />
          <div class="col-lg-6 d-flex align-items-center"><img id="imgUnterricht" class="img-fluid tweenFromRight" src="~/assets/arbeitsblatt_ar.jpg" alt=""></div>
          <!-- <div class="col-lg-6"><img id="imgUnterricht" class="img-fluid tweenFromRight" src="~/assets/unterricht.jpg" alt=""></div> -->
        </div>
        <div class="row triggerTweenSides">
          <SectionHalf
              class="col-lg-6 containerHalfSection tweenFromLeft"
              bgcolor="whitegrey"
              category="16"
              targetPage="aktionen"
              sectionName="Aktionen & Wettbewerbe"
          />
          <div class="col-lg-6 d-flex align-items-center"><img id="imgAktion" class="img-fluid tweenFromRight" src="~/assets/aktion_cansat.jpg" alt=""></div>
        </div>

      </div>

      <div class="erfahreMehr d-flex align-items-center justify-content-center flex-column whitegrey2">
          <h1 class="animateUp">Erfahre mehr</h1>
          <!-- <div class="links"> -->
            <!-- <div class="button--green animateUp">Jetzt Kontakt aufnehmen</div> -->
            <a class="button--green animateUp" href="mailto:info@esero.de" style="text-decoration: none">Jetzt Kontakt aufnehmen</a>
          <!-- </div> -->
      </div>
      <!-- <div id="erfahreMehr" class="d-lg-flex align-items-center flex-column whitegrey2 section">
          <h4 class="headerText animateUp">Erfahre mehr</h4>
          <div class="subtitle">Jetzt Kontakt aufnehmen</div>
          <div class="button--green accentcolor animateUp">Nac</div>
      </div> -->

    </div>

  </div>
</template>


<script>
import AppLogo from '~/components/AppLogo.vue';
import Section from '~/components/Section.vue';
import SectionSimple from '~/components/SectionSimple.vue';
import SectionHalf from '~/components/SectionHalf.vue';
import axios from 'axios';
// import VModal from 'vue-js-modal';
// Vue.use(VModal)

import { TweenMax, Back } from 'gsap'
import { faDivide } from '../sources/fontawesome-free-5.0.13/advanced-options/use-with-node-js/fontawesome-free-solid';

// import ScrollMagic from 'scrollmagic';
// >> again, doesnt work with npm.. had to do cdnn

export default {
  components: {
    AppLogo,
    Section,
    SectionSimple,
    SectionHalf
  }
  ,
  data() {
        return {
            windowwidth: 100,
            headerTitle: '',
            headerImage: '',
            dataTitle: [],
            dataIntro: []
        };
  }
  ,
  mounted: function() {

    // $(document).ready(function(){
        // init ScrollMagic
        var controller = new ScrollMagic.Controller();


        // >>> animate Up see slide 2
        var testtt = new ScrollMagic.Scene({
		        triggerElement: '#education', // trigger CSS animation when header is in the middle of the viewport
            triggerHook: 0.7,
            reverse: false
		    })
        .setClassToggle('#education', 'is-active') // set class to active slide
        // .setTween(TweenMax.to('#education', 1, {y: '-20%', autoAlpha: 0.3, ease:Power0.easeNone}))
        // .addIndicators()
        .addTo(controller);
        // !!!!!!!!!!****************** CSS look for:
        // background for header >> -header-container
        // .bcg >> height for full screen image or not
        // header container wrapper >> vertical position of mainLogo
        // mainLogo
        // .project and .project.fade-in
        // ***************************************

        var animateMainProducts = new ScrollMagic.Scene({
		        triggerElement: '#mainTasks', // trigger CSS animation when header is in the middle of the viewport
            triggerHook: 0.8,
            reverse: false
		    })
        .setClassToggle('#mainTasks', 'is-active') // set class to active slide
        // .setTween(TweenMax.to('#education', 1, {y: '-20%', autoAlpha: 0.3, ease:Power0.easeNone}))
        // .addIndicators()
        .addTo(controller);


        // ***landing page side by side > loop
        $('.triggerTweenSides').each(function(){
          var triggerRight = $(this).find('.tweenFromRight');
          var triggerLeft = $(this).find('.tweenFromLeft');
          var tweensFromSide = new TimelineMax();
          tweensFromSide
          .from(triggerRight, 1, {x: '10%', autoAlpha: 0, ease:Power1.easeOut})
          .from(triggerLeft, 1, {x: '-10%', autoAlpha: 0, ease:Power1.easeOut}, '-=1');

          var landingPageSideBySide = new ScrollMagic.Scene({
                triggerElement: this,
                triggerHook: 0.7,
                reverse: false
                // duration: "100%"
            })
            .setTween(tweensFromSide)
            // .addIndicators()
            .addTo(controller);
        });

        // ***Section: Erfahre mehr
        var erfahreMehrTween = new ScrollMagic.Scene({
		        triggerElement: '.erfahreMehr',
            triggerHook: 0.7,
            reverse: false
		    })
        .setClassToggle('.erfahreMehr', 'is-active') // set class to active slide
        // .setTween(TweenMax.to('#education', 1, {y: '-20%', autoAlpha: 0.3, ease:Power0.easeNone}))
        // .addIndicators()
        .addTo(controller);




        // get all slides
        var slides = ["#slide01", "#slide02", "#slide03"];
        // get all headers in slides that trigger animation
        var headers = ["#slide01 header", "#slide02 header", "#slide03 header"];
        // get all break up sections
        var breakSections = ["#cb01", "#cb02", "#cb03"];

        // Enable ScrollMagic only for desktop, disable on touch and mobile devices
        if (!Modernizr.touch) {

            // SCENE 1
            // create scenes for each of the headers
            headers.forEach(function (header, index) {

                // number for highlighting scenes
                var num = index+1;

                // make scene
                var headerScene = new ScrollMagic.Scene({
                    triggerElement: header, // trigger CSS animation when header is in the middle of the viewport
                    offset: -95 // offset triggers the animation 95 earlier then middle of the viewport, adjust to your liking
                })
                .setClassToggle('#slide0'+num, 'is-active') // set class to active slide
                // .addIndicators() // add indicators (requires plugin), use for debugging
                .addTo(controller);
            });

            // SCENE 2 >>> NON IMAGE SECTION
            // change color of the nav for dark content blocks
            breakSections.forEach(function (breakSection, index) {

              // number for highlighting scenes
              var breakID = $(breakSection).attr('id');

              // make scene
              var breakScene = new ScrollMagic.Scene({
                  triggerElement: breakSection, // trigger CSS animation when header is in the middle of the viewport
                  triggerHook: 0.75
              })
              .setClassToggle('#'+breakID, 'is-active') // set class to active slide
              .addTo(controller);
            });




            // SCENE 4 - parallax effect on each of the slides with bcg
            // move bcg container when slide gets into the view
            slides.forEach(function (slide, index) {

                var $bcg = $(slide).find('.bcg');

                var slideParallaxScene = new ScrollMagic.Scene({
                    triggerElement: slide,
                    triggerHook: 1,
                    duration: "100%"
                })
                .setTween(TweenMax.from($bcg, 1, {y: '-40%', autoAlpha: 0.3, ease:Power0.easeNone}))
                .addTo(controller);
            });

                // ***parallax effect on the intro slide
    // move bcg container when intro gets out of the the view
    var introTl = new TimelineMax();
    introTl
        // .to($('#intro header, .scroll-hint'), 0.2, {autoAlpha: 0, ease:Power1.easeNone}) // the logo and scroll hint
        // hier eigentlich kein tween sondern css class trigger
        // .to($('#intro header, #arrowArea'), 0.4, {opacity:"0", delay:1, ease:Power2.easeOut}) // the logo and scroll hint
        .to($('#arrowArea'), 0.6, {opacity:"0", ease:Power2.easeOut}) // the logo and scroll hint
        // .to($('#intro .bcg'), 1.4, {y: '20%', ease:Power1.easeOut}, '-=0.2') // the backgrond image parallax
        .to($('.bcg'), 1.4, {y: '20%', ease:Power1.easeOut}, '-=0.6') // the backgrond image parallax
        // .to($('#intro'), 0.7, {autoAlpha: 0, delay:0.3, ease:Power1.easeNone}, '-=1.4'); //this is the whole div
        // .to($('.header-container .wrapper, .bcg'), 0.7, {autoAlpha: 0, delay:0.2, ease:Power1.easeNone}, '-=1.4') //this is the whole div
        .to($('.header-container .wrapper'), 0.7, {autoAlpha: 0, delay:0.2, ease:Power1.easeNone}, '-=1.4') //this is the whole div
    var introScene = new ScrollMagic.Scene({
        triggerElement: '#intro',
        triggerHook: 0,
        duration: "100%"
    })
    .setTween(introTl)
    .addTo(controller);

            // change behaviour of controller to animate scroll instead of jump
            controller.scrollTo(function (newpos) {
                TweenMax.to(window, 1, {scrollTo: {y: newpos}, ease:Power1.easeInOut});
            });

            //  bind scroll to anchor links
            $(document).on("click", "a[href^='#']", function (e) {
                var id = $(this).attr("href");
                if ($(id).length > 0) {
                    e.preventDefault();

                    // trigger scroll
                    controller.scrollTo(id);

                        // if supported by the browser we can even update the URL.
                    if (window.history && window.history.pushState) {
                        history.pushState("", document.title, id);
                    }
                }
            });

        }


        // Parallax intro and background





        // pin the intro
        // var pinIntroScene = new ScrollMagic.Scene({
        //     triggerElement: '#intro',
        //     triggerHook: 0,
        //     duration: '20%'
        // })
        // .setPin('#intro', {pushFollowers: false})
        // .addTo(controller);

        // loop through each section
        $('.project').each(function(){
            // console.log(this);
            // build a scene
            var ourScene = new ScrollMagic.Scene({
                // triggerElement: '#project01',
                // triggerElement: '#project01 h4.headerText',
                triggerElement: this.children[0].childNodes[0],
                // duration: '90%',
                triggerHook: 0.9,
                // reverse: false
            })
            .setClassToggle(this, 'fade-in') // add class to project01
            // .addIndicators()
            .addTo(controller);
        })
    // });


    //   ### switch icons depending on width
    // ### but will keep only the small icon, looks better
    //   this.windowwidth = $( window ).width();
    //   let vm = this;
    //   window.onresize = function () { vm.resizer() };
    //   window.addEventListener('resize', vm.resizer);

    // ### das geht irgendwie nicht.. erkennt die src nicht
    // window.addEventListener('resize', widthChangeLogo);
    // function widthChangeLogo() {
    //     if (document.body.clientWidth > 600 || document.documentElement.clientWidth > 600) {
    //         $('img.testt').attr('src', 'wp-content/themes/simple-fs/vueapp/assets/esero_flag_sign_Germany_blue.png');
    //     } else if (document.body.clientWidth < 600 || document.documentElement.clientWidth < 600) {
    //         $('img.testt').attr('src', 'assets/esero_country_name_blue_r.png');
    //     }
    // }

    // *****! use addEventListener instead of window.onscroll!!! That way you don't override window.onscroll! Multiple window.onscroll doesn't work!!!
    // window.addEventListener('scroll', scrollToRemoveFlag);
    //  function scrollToRemoveFlag() {
    //     //  ### also here.. setting opacity with attr would be a lot easier but didnt work
    //     if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    //             $('figure').addClass('opac');
    //         } else if (document.body.scrollTop < 20 || document.documentElement.scrollTop < 20) {
    //             $('figure').removeClass('opac');
    //             $('#arrowArea').removeClass('opac');
    //         }
    //     if (document.body.scrollTop > 300 || document.documentElement.scrollTop > 300) {
    //         $('#arrowArea').addClass('opac');
    //         }
    //  }

    // ### way with detach just didnt work, didnt come back
    // function scrollToRemoveFlag() {
        // var elll;
        // if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            // $('figure').removeClass('logomainSign');
            // elll = $('figure').detach();
            // $('figure.centered').attr('top', '10%');
        // } else if (document.body.scrollTop < 20 || document.documentElement.scrollTop < 20) {
            // elll.appendTo( "body" );
            // $(this).append( elll );
            // shouldnt it be... .show()
            // $('body').prepend(elll);
            // $('figure').addClass('logomainSign');
        // }
    // }


        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
        axios.get(`https://esero.de/wp-interface/index.php/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=10`)
          .then(response => {
              response.data.map( (item) => {
                  if (item.categories.includes(22)) {
                      this.dataTitle = item.title.rendered
                      this.dataIntro = item.content.rendered
                  }
              })
          })
          .catch(e => { this.errors.push(e) });

        // ***header image and headline
        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true/63`)
        axios.get(`https://esero.de/wp-interface/index.php/wp-json/wp/v2/posts?_embed=true/63`)
        .then(response => {
            // this.headerPostData = reponse.data
            this.headerTitle = response.data[0].content.rendered
            this.headerImage = response.data[0]._embedded['wp:featuredmedia'][0].source_url
        })
        .catch(e => { this.errors.push(e) });

  },
  methods: {
    //   resizer() {
    //       this.windowwidth = $( window ).width();
    //   }
    },
   transition: {
        mode: 'out-in',
        css: false,
        beforeEnter (el) {
            console.log('beforeEnter index')
            TweenMax.set(el, {
                opacity: 0
            })
        },
        enter (el, done) {
            TweenMax.to(el, 0.7, {
                opacity: 1,
                onComplete: done
                // ease: Back.easeOut
            })
            // done()
        }
        // ,
        // beforeLeave (el, done) {
        //     console.log('beforeLeave index')
        //     TweenMax.to(el, 1, {
        //         opacity: 0,
        //         color: 'red'
        //         // ease: Back.easeOut
        //     })
        // }
        ,
        leave (el, done) {
            console.log('leave index')
            TweenMax.to(el, 0.7, {
                opacity: 0,
                // color: 'red',
                onComplete: done
            })
            // done()
        }
   }
}
</script>

<style>
[v-cloak] {
  display: none;
}


/* sections and scrollagic */
/* #intro {
    height: 100vh;
    background: radial-gradient(circle, #74A6EE 0%, #485CB2 100%);
    text-align: center;
    color: #fff;
} */
#main {
    position: relative;
    background-color: #0e141e;
}
.project {
    text-align: center;
    padding: 200px 0;
    border-bottom: 1px #ebebeb solid;
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-out;
}
.project.fade-in {
    opacity: 1;
    transform: translateY(0);
}
.project img {
    width: 180px;
    height: 180px;
}
.project p {
    max-width: 300px;
    margin: 0 auto;
    color: #294D67;
}



.arrowBackground {
    position: absolute;
    /* width: 50px; */
    margin-left: -30px;
    /* margin-right: 30px; */
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    border-left: 30px solid #009ada;
    border-right: 30px solid #009ada;
    height: 100px;
    /* background-color: #009ada; */
    left: 50%;
    bottom: 0;
    opacity: 0.8;
}
#arrowArea {
    transition: all 0.8s ease;
}
#arrowArea.opac {
    opacity: 0;
}

/* #downArrow {
  padding-top: 80px;
} */
#downArrow span {
  position: absolute;
  /* top: 0; */
  /* bottom: 0; */
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
/* #downArrow a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#downArrow a span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
#downArrow a span:nth-of-type(3) {
  top: 32px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
} */
#downArrow span:nth-of-type(1) {
  /* bottom: 52px; */
  bottom: 62px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#downArrow span:nth-of-type(2) {
  /* bottom: 36px; */
  bottom: 46px;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
#downArrow span:nth-of-type(3) {
  /* bottom: 20px; */
  bottom: 30px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}
@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* #downArrow {
  padding-top: 80px;
} */
#downArrow3 span {
  position: absolute;
  /* top: 0; */
  bottom: 0;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  /* border-left: 1px solid #fff; */
  /* border-bottom: 1px solid #fff; */
  border-left: 1px solid #009ada;
  border-bottom: 1px solid #009ada;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb03 2s infinite;
  animation: sdb03 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#downArrow3 span:nth-of-type(1) {
  bottom: 57px;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#downArrow3 span:nth-of-type(2) {
  bottom: 41px;
  -webkit-animation-delay: .15s;
  animation-delay: .15s;
}
#downArrow3 span:nth-of-type(3) {
  bottom: 25px;
  -webkit-animation-delay: .3s;
  animation-delay: .3s;
}
@-webkit-keyframes sdb03 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb03 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* #downArrow2 {
  padding-top: 70px;
} */
#downArrow2 span {
  position: absolute;
  /* top: 0; */
  bottom: 25px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}






/* ----------------------GENERAL */
/* p {
    text-align: justify;
} */
h2 {
/* #intro h2 { */
    padding: 50px 0 15px 0;
    font-size: 36px !important;
    /* font-weight: 700 !important;  */
    /* word-spacing: -8px;  */
    /* line-height:52px !important;  */
    /* margin-left: -3px;  */
    /* letter-spacing: -1px; */
}
h3 {

    padding: 50px 0 15px 0;
    /* font-size: 36px !important;  */
}
/* h4.headerText {
    font-size: 13px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    line-height: 20px !important;
    letter-spacing: 5px;
    color: #009ada;
} */
h4.headerText {
  display: inline-block;
  margin-bottom: 15px !important;
  padding: 7px 5px 7px 0px;
  /* border: 1px none #2dca9a; */
  /* border-radius: 3px !important; */
  font-family: 'Montserrat', sans-serif;
  color: #009ada;
  font-size: 25px !important;
  line-height: 9.5px !important;
  /* font-weight: 200 !important; */
  text-align: center;
  letter-spacing: 2px !important;
  text-transform: uppercase;
}
h2.subtitle {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0 !important;
  font-family: 'Montserrat', sans-serif;
  color: #505050;
  font-size: 25px !important;
  line-height: 35px;
  font-weight: 200;
}
p.content {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  color: #a4a7a9;
  font-size: 17.5px;
  line-height: 30px;
  font-weight: 300;
}
.section {
    padding-top: 80px;
    padding-bottom: 150px;
}
.blackbg {
    background: #0e141e;
    color: white;
}
.whitebg {
    background: white;
    color: #212529;
}
.greylightbg {
    background: #39424e;
    color: white;
}
.greymediumbg {
    background: #303944;
    color: white;
}
.greymediumdarkbg {
    background: #28303b;
    color: white;
}
.greydarkbg {
    background: #1f252d;
    color: white;
}
.whitegrey {
    background: #f9f9f9;
    color: #35495e;
}
.whitegrey2 {
    background: #e8e8e8;
    color: #35495e;
}
.columns {
    column-count: 2;
    column-gap: 40px;
    /* column-rule-style: solid;
    column-rule-width: 1px; */
    /* text-align: center; */
}
/* h2:after { content:"\2589"; color:#32c766; animation: 1.2s blink infinite; margin-left: 2px; } */
.sidebar {
    /* border-left:3px solid #2eb9ce; */
    border-left:3px solid #009ada;
    padding-left: 10px;
}
.accentcolor {
    color: #009ada;
}


/* >>>>>>>>>> also remove scrollbar but allow mouse and touch pan */
  /* overflow: hidden; */
/* the body html thing also not needed it seems */
/* body, html {
  height: 100%;
  margin: 0;
} */
.headerbg {
    /* background-image: url("~/assets/mosel.jpg"); */
    /* background-image: url(~/assets/mosel.jpg); */
    /* this below also works.. and they say it actually should be without quotes.. and when in static folder doesn't even need to be with a path */
    /* background-image: url(/mosel.jpg); */

    /* >>>> use this one for local development again */
    /* background-image:  url(/assets/s2b_header.png); */
    /* >>> with gradient to put text ontop */
    /* background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    ), url(/assets/s2b_header.png); */
    /* >>> with blur on bottom to put text ontop */
    /* ... */

    /* >>> EXAMPLE for static build process (not the way to go, of course we want relative paths, which always work), place image in static folder and then link static
    however.. don't understand why it doesn't work with assets, in static build they are in img folder under _nuxt but not accessed */
    /* background-image: url(/esero/dist/s2b_header.png); */
    /* >>> EXAMPLE static end */

    /* height: 100%; */
    /* width: 100%; */
    /* position: absolute; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.6;
    /* filter: blur(2px); */
    /* filter: grayscale(60%) blur(2px) brightness(80%); */
    /* filter: grayscale(60%) brightness(80%); */

    min-height: 100vh; /* <<<<<<<<< this has been what finally made it work */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* text-align: center; */
}


/* for logo ontop of header image with background shadow */
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
figure {
    transition: all 0.8s ease;
}
figure.opac {
    opacity: 0;
}
/* figure > div > img {
    filter: grayscale(100%);
} */
.logomainFlag {
   -webkit-filter: drop-shadow(8px 8px 10px gray);
    filter: drop-shadow(8px 8px 10px gray);
}
.logomainSign {
   /* -webkit-filter: drop-shadow(8px 8px 10px gray); */
    /* filter: drop-shadow(8px 8px 10px gray); */
    /* width: 600px; */
    border: 10px solid #009ada;

    width: 60%;
    max-width: 800px;
    min-width: 300px;
    background-color: #009ada;
    margin-bottom: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* ******** flex containers
************************** */
.flexContainerCenteredColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flexItemColumn {
  width: 90%;
  /* height: 300px; */
  margin: auto;
}
.flexContainerRow {
  padding-top: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
}
.flexItemRow {
  width: 400px;
  text-align: center;
}

#mainTasks {
  /* padding-top: 200px; */
  /* padding-bottom: 600px; */
  /* text-align: center; */
  /* max-width: 800px; */
  /* width: 800px; */
}
.mainTastItem {
  /* width: 400px; */
  /* text-align: center; */
}

.mainLogo {
    border: 10px solid #009ada;
    width: 100%;
    background-color: #009ada;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media only screen and (min-width: 600px) {
  .mainLogo {
      border: 10px solid #009ada;
      width: 70%;
      max-width: 800px;
  }
}
/* responsive text */
.captionadjust {
  padding: 30px 20px 10px 20px;
  font-family: 'Caveat';
  font-size: 4vw;
  color: white;
}
@media only screen and (max-width: 600px) {
  .captionadjust {
    /* font-size: 20px; */
    font-size: 2em;
    /* font-size: 10vmin; */
  }
}
@media screen and (min-width: 1500px) {
  .captionadjust {
     font-size: 60px; /* 4vw, so 4% of 1500px */
  }
}

/* logo ontop of header END */
.caption {
  position: absolute;
  left: 0;
  top: 35%;
  width: 100%;
  text-align: center;
  color: #000;
}
/* .caption span.headertext { */
.caption .headertext {
  /* background-color: #111; */
  filter: drop-shadow(8px 8px 10px gray);
  color: #fff;
  padding: 18px;
  /* font-size: 25px; */
  letter-spacing: 5px;

    font-family: Futura, "Trebuchet MS", Arial, sans-serif;
    font-size: 8vw;
    font-style: normal;
    font-variant: normal;
    /* font-weight: 700; */
    font-weight: 100;
    /* line-height: 26.4px; */
    line-height: 5vw;
}


/* .container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */
/* >>> using the standard bootstrap container instead */

.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}


/* --------------------------------------------------- */












/* ==========================================================================
   Author's custom styles
   ========================================================================== */
/* =Header */
.header-container {
  text-align: center;
  color: #ffffff;
  background: #f9f9f9;
  }
  .header-container .wrapper {
    /* padding-top: 120px; */

    /* used to be: */
    /* top: 60%;
    z-index: 2;
    position: relative; */

    bottom: 11%;
    /* now, to fix on same position bottom */
    /* bottom: -111%;  */
    /* goes to 11%, this is to make pageload animation (the 'from' animation would be nice but had the artifact) */
    z-index: 2;
    position: absolute;
    width: 100%;
    /* transition: 1s; */
    }

.bcg {
  background: no-repeat center center;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 80%;
  z-index: 1;
  opacity: 0.5; }
  .header-container .bcg {
    background-image: url(~/assets/s2b_header.png);
    background-size: cover; }
  #slide01 .bcg {
    background: url(/assets/s2b_header.png) no-repeat center center;
    background-size: cover; }
  #slide02 .bcg {
    background: url(/assets/s2b_header.png) no-repeat center center;
    background-size: cover; }
  #slide03 .bcg {
    background: url(/assets/s2b_header.png) no-repeat center center;
    background-size: cover;
    opacity: 0.7; }


.scroll-hint {
  color: #212121;
  position: absolute;
  bottom: 30px;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 10px 40px;
  border: 3px #ffffff solid;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Roboto Condensed', Helvetica, Helvetica Neue, Arial, sans-serif;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2; }

/* =Main */
/* p {
  font-size: 16px; } */

.fs {
  height: 100vh; }

.slide {
  color: #ffffff;
  position: relative; }
  .slide .wrapper {
    padding-top: 30px;
    text-align: center;
    position: relative;
    z-index: 2; }
  .slide#slide01 .wrapper {
    position: absolute;
    top: 10%; }
  .slide#slide02 .wrapper {
    position: absolute;
    bottom: 10%; }
  .slide#slide03 .button {
    border-color: #ffffff;
    color: #212121;
    background-color: rgba(255, 255, 255, 0.9); }
    .slide#slide03 .button:hover {
      border-color: #212121;
      background-color: #212121;
      color: #ffffff; }
  .slide#slide03 .wrapper {
    padding-top: 100px; }
  .slide#slide04 {
    background-color: #be4c2c; }

.button {
  text-decoration: none;
  color: #ffffff;
  border: 1px rgba(255, 255, 255, 0.7) solid;
  padding: 8px 15px;
  margin-top: 15px;
  display: inline-block;
  position: relative; }
  .button:hover {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #000000; }

/* =Content Block */
.content-block {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.9); }
  .content-block .wrapper {
    padding: 30px 0;
    text-align: center; }
  .no-touch .content-block .wrapper {
    visibility: hidden;
    opacity: 0; }
  .no-touch .content-block.is-active .wrapper {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); }

/* =Footer */
.footer-container {
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #06060d; }
  .footer-container h3 {
    margin: 0;
    padding: 20px 0;
    font-weight: normal; }
  .footer-container a {
    color: rgba(255, 255, 255, 0.5); }

/* =Simple animation up */
.no-touch .slideInUp {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.7s ease-out;
          transition: all 0.7s ease-out;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0); }
.no-touch .is-active .slideInUp {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
.slideInUp.slideInUp2 {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s; }
.slideInUp.slideInUp3 {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s; }

#iht_logo {
  width: 100px;
  margin-bottom: 30px; }


/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (min-width: 768px) {
  /* body {
    font-weight: 100; } */
  /* h1 {
    font-size: 3em; } */
  /* p {
    font-size: 20px; } */
  /* =Header */
  .header-container {
    overflow: hidden;
    position: relative;
    }
    /* .header-container .wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -120%);
          -ms-transform: translate(-50%, -120%);
              transform: translate(-50%, -120%);
      margin: 0;
      padding: 0; }
    .header-container:before {
      font-size: 70em; } */
  /* .scroll-hint {
    font-size: 20px; } */
  /* .bcg {
    opacity: 1; } */
  .slide {
    overflow: hidden;
    overflow-x: hidden; }
    .slide section {
      max-width: 400px; }
    .slide .wrapper {
      padding-top: 120px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      text-align: left;
      width: 100%;
      margin: 0; }
    .slide#slide01, .slide#slide02, .slide#slide03 {
      color: #212121; }
      .slide#slide01 .wrapper, .slide#slide02 .wrapper, .slide#slide03 .wrapper {
        top: 20%;
        text-align: center; }
      .slide#slide01 section, .slide#slide02 section, .slide#slide03 section {
        margin: 0 auto; }
    .slide#slide01 {
      color: #ffffff; }
    .slide#slide02 {
      color: #ffffff; }
      .slide#slide02 .wrapper {
        top: auto;
        bottom: 40%;
        left: 10%;
        text-align: left;
        -webkit-transform: translate(0);
            -ms-transform: translate(0);
                transform: translate(0);
        padding: 0; }
      .slide#slide02 section {
        margin: 0; }
    .slide#slide03 .wrapper {
      padding: 0;
      top: 15%;
      -webkit-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
              transform: translate(-50%, 0); }
    .slide#slide03 section {
      margin: 0 auto;
      max-width: 570px; }
    .slide#slide03 .button {
      border-color: rgba(0, 0, 0, 0.7);
      background-color: transparent;
      color: #212121; }
    .slide#slide03 .bcg {
      opacity: 1; }
}


/* NEW NEW NEW NEW NEW NEW OWN STUFF*/

.animateUp {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.7s ease-out;
          transition: all 0.7s ease-out;
  -webkit-transform: translate3d(0, 50px, 0);
  transform: translate3d(0, 50px, 0);
}
.is-active .animateUp {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }
.animateUp.animateUp2 {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s; }
.animateUp.animateUp3 {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s; }


/* for the landing page half component */
.noPaddingXWidth {
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
}
.containerHalfSection {
  padding-left: 10%;
  /* padding-bottom: 200px; */
  padding-top: 100px;
  padding-right: 20px;
}

.erfahreMehr {
  padding-top: 80px;
  padding-bottom: 80px;
}
.erfahreMehr .button--green {
    color: #009ada;
    border-color: #009ada;
    /* background-color: #009ada; */
}
.erfahreMehr .button--green:hover {
    color: white;
    background-color: #009ada;
}
.erfahreMehr2 {
  padding-top: 20px;
}
.erfahreMehr2 .button--green {
    color: #009ada;
    border-color: #009ada;
    /* background-color: #009ada; */
}
.erfahreMehr2 .button--green:hover {
    color: white;
    background-color: #009ada;
}

#imgUnterricht {
  opacity: 1;
  /* -webkit-mask-image: linear-gradient(110deg, transparent 25%, black 85%); */
  /* mask-image: linear-gradient(110deg, transparent 25%, black 85%); */
  -webkit-mask-image: linear-gradient(91deg, transparent 10%, black 85%);
  mask-image: linear-gradient(91deg, transparent 10%, black 85%);
  /* mask-image: linear-gradient(220deg, rgb(0, 0, 0) 35%, rgba(255, 255, 255, 0) 100%); */
}
#imgAktion {
  opacity: 0.8;
  -webkit-mask-image: linear-gradient(55deg, transparent 10%, black 85%);
  mask-image: linear-gradient(55deg, transparent 10%, black 85%);
  /* -webkit-mask-image: radial-gradient(ellipse at bottom left, transparent 25%, black 85%);
  mask-image: radial-gradient(ellipse at bottom left, transparent 25%, black 85%); */
  /* -webkit-mask-image: radial-gradient(ellipse at bottom right, transparent 25%, black 85%);
  mask-image: radial-gradient(ellipse at bottom right, transparent 25%, black 85%); */
}
#imgFortbildungen {
    opacity: 1;
  /* -webkit-mask-image: linear-gradient(55deg, transparent 10%, black 85%); */
  /* mask-image: linear-gradient(55deg, transparent 10%, black 85%); */
    -webkit-mask-image: linear-gradient(85deg, transparent 6%, black 88%);
  mask-image: linear-gradient(85deg, transparent 6%, black 88%);
  /* max-height: 600px; */
}
/* background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(246,246,246,0.96) 8%, rgba(0,0,0,0.56) 100%);
background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(255,255,255,1)), color-stop(8%, rgba(246,246,246,0.96)), color-stop(100%, rgba(0,0,0,0.56)));
background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(246,246,246,0.96) 8%, rgba(0,0,0,0.56) 100%);
background: -o-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(246,246,246,0.96) 8%, rgba(0,0,0,0.56) 100%);
background: -ms-radial-gradient(center, ellipse cover, rgba(255,255,255,1) 0%, rgba(246,246,246,0.96) 8%, rgba(0,0,0,0.56) 100%);
background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(246,246,246,0.96) 8%, rgba(0,0,0,0.56) 100%); */

.triggerTweenSides {
  padding-bottom: 100px;
}

.mainTasksContainer {
  padding-left: 10px;
  padding-right: 10px;
}

</style>

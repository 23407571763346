<template>
    <div class="container-fluid" :class="bgcolor" id="education">
        <div class="container section">
            <h4 class="headerText">{{ sectionName }}</h4>
            <div class="sidebar">
                <h2>{{ dataTitle }}</h2>
                <div class="columns" v-html="dataIntro"></div>
            </div>

            

        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    components: {
        
        },
    props: ['bgcolor', 'category', 'sectionName'],
    data() {
        return {
            dataTitle: [],
            dataIntro: []
        };
    },
    mounted() {

        // axios.get(`http://localhost:80/eseronuxt/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
        axios.get(`https://esero.de/wp-interface/index.php/wp-json/wp/v2/posts?_embed=true&per_page=3&categories=${this.category}`)
            .then(response => { 
                response.data.map( (item) => {
                    if (item.categories.includes(22)) {
                        this.dataTitle = item.title.rendered
                        this.dataIntro = item.content.rendered
                    } 
                })
            })
            .catch(e => { this.errors.push(e) });
    }
};


</script>

<style scoped>
/* many styles are already defined in index.. which is not scoped */

.accentcolor {
    color: #009ada;
    /* border: 1px solid #009ada; */
    /* color: white; */
    /* border: 1px solid white; */
    border: 1px solid #009ada;
}
.button--green:hover {
    color: white;
    background-color: #009ada;
}


.title {
  font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* 1 */
  display: block;
  font-weight: 300;
  font-size: 100px;
  color: #35495e;
  letter-spacing: 1px;
}

.subtitle {
  font-weight: 300;
  font-size: 42px;
  color: #526488;
  word-spacing: 5px;
  padding-bottom: 15px;
}

.links {
  padding-top: 15px;
}

/* 3 posts preview */
.postscontainer {
    margin-top: 180px;
}
.postitem {
    padding: 20px;
    margin: 10px;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    background-color: white;
    color: #212529;
    /* -webkit-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.4); */
    /* -moz-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.4); */
    /* box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.4); */
    /* box-shadow: 0px 0px 6px 1px rgba(241, 8, 8, 0.7); */
    /* z-index: 99999999; */
}
.postitem:hover {
    /* -webkit-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.75); */
    /* -moz-box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 28px -9px rgba(0,0,0,0.75); */
    box-shadow: 0px 0px 3px 1px rgba(255, 255, 255, 0.7);
}
.heading2 {
    /* font-family: "Open Sans", sans-serif; */
    font-family: "Open Sans", sans-serif;
    /* font-family: "Open Sans Condensed", sans-serif; */
    text-transform: none;
    font-size: 25px;
    line-height: 35px;
    font-weight: 300;
    letter-spacing: -0.5px;
}
</style>
